
import { defineComponent, ref, watchEffect } from 'vue'
import { useRouter } from '@/utils/composables'
import WorklistManager from '@/components/worklist/worklist-manager.vue'

export default defineComponent({
	components: {
		[WorklistManager.name]: WorklistManager,
	},
	setup() {
		const router = useRouter()

		const qs = router.app.$route.query
		const limit = ref(qs.limit ? Number(qs.limit) : 10)
		const page = ref(qs.page ? Number(qs.page) : 1)
		const query = ref((qs.query as string) ?? '')
		const userName = ref((qs.user_name as string) ?? '')

		watchEffect(() => {
			const queryString = router.app.$route.query

			limit.value = Number(queryString.limit) || 10
			page.value = Number(queryString.page) || 1
			query.value = (queryString.query as string) || ''
			userName.value = (queryString.user_name as string) || ''

			window.scrollTo(0, 0)
		})

		return {
			limit,
			page,
			query,
			userName,
		}
	},
})
