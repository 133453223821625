
import { defineComponent, onMounted, onUnmounted, ref, watchEffect } from 'vue'
import queryString from 'query-string'

import { useRouter } from '@/utils/composables'
import { Alert, toast } from '@/dependencies/sweet-alert'

import { MyndshftPanel } from '@myndshft/panel'
import EditWorklistName from '@/components/worklist/manager/edit-worklist-name.vue'
import EditWorklistUser from '@/components/worklist/manager/edit-worklist-user.vue'
import ModifiedbyTooltip from '@/components/worklist/shared/modifiedby-tooltip.vue'
import MyndshftSearch from '@/components/search/search.vue'

import {
	fetchWorklists,
	updateWorklist,
	fetchWorklistUsers,
	deleteWorklist,
} from '@/service/worklist.http'

import { AxiosError } from 'axios'
import {
	IDisplayUser,
	IWorklist,
	IWorklists,
	IWorklistUser,
	WorklistStatus,
	WorklistStatusName,
} from '@server/api/models/fe/worklist'

interface IHeader {
	text: string
	value: string
	width?: string
}

interface IEditableWorklist extends IWorklist {
	tempName: string
	tempAssigneeId: string
	editing?: boolean
	isValid?: boolean
}

export default defineComponent({
	name: 'worklist-manager',
	components: {
		MyndshftPanel,
		EditWorklistName,
		EditWorklistUser,
		ModifiedbyTooltip,
		MyndshftSearch,
	},
	props: {
		limit: {
			type: Number,
			default: 10,
		},
		page: {
			type: Number,
			default: 1,
		},
		query: {
			type: String,
			default: '',
		},
		userName: {
			type: String,
			default: '',
		},
	},
	setup(props) {
		const router = useRouter()

		const worklists = ref<IEditableWorklist[]>([])
		const users = ref<IDisplayUser[]>([])
		const tablePage = ref<number>(props.page)
		const totalCount = ref<number>(0)
		const totalPages = ref<number>(0)
		const currentCountFrom = ref(0)
		const currentCountTo = ref(0)
		const worklistStatus = WorklistStatus
		const worklistStatusName = WorklistStatusName
		const isEditingWorklist = ref(false)
		const updatedWorklist = ref<IEditableWorklist>({
			assigneeId: '',
			id: '',
			name: '',
			tempName: '',
			tempAssigneeId: '',
			status: WorklistStatus.READY,
			count: 0,
			meta: {
				id: '',
				datetime: '',
				displayDatetime: '',
			},
		} as IEditableWorklist)
		const headers: IHeader[] = [
			{ text: '', value: 'edit', width: '10px' },
			{ text: 'Name', value: 'name', width: '250px' },
			{ text: 'Status', value: 'status' },
			{ text: 'Total', value: 'count', width: '110px' },
			{ text: 'In Progress', value: 'inProgressCount', width: '150px' },
			{ text: 'In Review', value: 'inReviewCount', width: '150px' },
			{ text: 'User', value: 'assigneeId' },
			{ text: 'Actions', value: 'save', width: '110px' },
		]
		let timeoutRef: any = null
		const initialQuery = props.query
		const isLoadingUsers = ref(false)

		onMounted(() => {
			queryWorklists()
			fetchUsers()
		})

		const fetchUsers = (): void => {
			isLoadingUsers.value = true
			fetchWorklistUsers()
				.then((worklistUsers: IWorklistUser[]) => {
					users.value = worklistUsers
						.map(user => {
							return {
								id: user.assigneeId,
								name: user.assigneeId.substr(0, user.assigneeId.indexOf('@')),
							}
						})
						.sort((user1, user2) => {
							return user1.name.toLowerCase() > user2.name.toLowerCase()
								? 1
								: -1
						})
				})
				.finally(() => {
					isLoadingUsers.value = false
				})
		}

		const queryWorklists = (): void => {
			clearTimeout(timeoutRef)
			timeoutRef = null
			fetchWorklists(
				props.limit,
				tablePage.value,
				props.query,
				props.userName
			).then((response: IWorklists) => {
				// If editing and polling, do not update worklist
				if (!isEditingWorklist.value) {
					worklists.value = response?.worklists?.map((item: any) => {
						item.editing = false
						item.isValid = false
						item.tempName = item.name
						item.tempAssigneeId = item.assigneeId
						return item
					})
					setCounts(response)
				}

				const polling = response?.worklists?.find((item: IWorklist) => {
					return item.status === WorklistStatus.NOT_READY
				})

				if (polling) {
					pollWorklists()
				}
			})
		}

		const pollWorklists = (): void => {
			timeoutRef = setTimeout(() => {
				queryWorklists()
			}, 10000)
		}

		const getStatus = (status: WorklistStatus): string => {
			switch (status) {
				case worklistStatus.READY:
					return 'is-ready'
				case worklistStatus.NOT_READY:
					return 'is-notready'
				case worklistStatus.IN_PROGRESS:
					return 'is-inprogress'
				default:
					return ''
			}
		}

		const onUpdateName = (item: IEditableWorklist): void => {
			worklists.value.map(editedItem => {
				if (editedItem.id === updatedWorklist.value.id) {
					editedItem.isValid = item.isValid
				}
				return item
			})
			updatedWorklist.value.name = item.name
		}

		const onUpdateUser = (assigneeId: string): void => {
			worklists.value.map(editedItem => {
				if (editedItem.id === updatedWorklist.value.id) {
					// Ensure worklist name is valid to save
					editedItem.isValid = !!editedItem.name.length
				}
				return editedItem
			})
			updatedWorklist.value.assigneeId = assigneeId
		}

		const onSaveWorklistItem = async (): Promise<void> => {
			try {
				await updateWorklist(updatedWorklist.value)

				isEditingWorklist.value = false
				queryWorklists()

				toast({ type: Alert.SUCCESS, title: 'Worklist updated' })
			} catch (error) {
				toast({
					type: Alert.ERROR,
					title: 'Failed to update worklist',
					error: error as AxiosError,
				})
			}
		}

		const onDeleteWorklist = (item: IEditableWorklist): void => {
			toast(
				{
					title: 'Deleting Worklist',
					html: `Are you sure you want to delete the worklist <strong>${item.name}</strong> with <strong>${item.count}</strong> items?`,
					confirmButtonText: 'Delete',
					confirmButtonColor: '#ef233c',
					showCancelButton: true,
					customClass: {
						container: 'swal2-container-full',
					},
				},
				true
			).then(result => {
				if (result.isConfirmed) {
					deleteWorklist(item.id)
						.then(() => {
							// Fetch updated worklist collection
							queryWorklists()

							toast({
								type: Alert.SUCCESS,
								title: 'Successfully deleted the worklist',
							})
						})
						.catch(() => {
							toast({
								type: Alert.ERROR,
								title: 'There was an error deleting the worklist',
							})
						})
				}
			})
		}

		const onEditWorklistItem = (item: IEditableWorklist) => {
			const isEditing = item.editing
			worklists.value.forEach((worklistItem: IEditableWorklist) => {
				worklistItem.editing = false
				worklistItem.name = worklistItem.tempName
				worklistItem.assigneeId = worklistItem.tempAssigneeId
				return worklistItem
			})

			item.editing = !isEditing

			// Prevents worklists from updating during polling
			isEditingWorklist.value = item.editing

			if (isEditingWorklist.value) {
				updatedWorklist.value = item
				// Set temp name and assignee id for editing
				updatedWorklist.value.tempName = item.name
				updatedWorklist.value.tempAssigneeId = item.assigneeId
			} else {
				// Reset item name and assignee id
				item.name = updatedWorklist.value.tempName
				item.assigneeId = updatedWorklist.value.tempAssigneeId
			}
		}

		const onCancelPolling = () => {
			clearTimeout(timeoutRef)
			timeoutRef = null
		}

		watchEffect(() => {
			queryWorklists()
		})

		const filterSearch = (input: string | null) => {
			const assignee =
				input === null ? null : input.includes('@') ? input : props.userName
			const query = input === null || input.includes('@') ? props.query : input
			if (query !== props.query || assignee !== props.userName) {
				const qs = queryString.stringify({
					limit: props.limit,
					page: 1,
					query,
					user_name: assignee,
				})
				router.push(`/worklist/list?${qs}`)
			}
		}

		const onUpdatePage = (page: number) => {
			const qs = queryString.stringify({
				limit: props.limit,
				page,
				query: props.query,
				user_name: props.userName,
			})
			router.push(`/worklist/list?${qs}`)
		}

		const setCounts = (res: IWorklists) => {
			totalCount.value = res.total
			totalPages.value = res.totalPages
			currentCountFrom.value =
				totalCount.value > 0 ? (tablePage.value - 1) * props.limit + 1 : 0
			const countTo = (tablePage.value - 1) * props.limit + props.limit
			currentCountTo.value = Math.min(countTo, totalCount.value)
		}

		onUnmounted(() => {
			onCancelPolling()
		})

		return {
			worklists,
			initialQuery,
			users,
			totalCount,
			totalPages,
			currentCountFrom,
			currentCountTo,
			worklistStatus,
			worklistStatusName,
			headers,
			tablePage,
			isEditingWorklist,
			isLoadingUsers,
			getStatus,
			onUpdateName,
			onUpdateUser,
			onUpdatePage,
			onSaveWorklistItem,
			onDeleteWorklist,
			onEditWorklistItem,
			filterSearch,
			onCancelPolling,
		}
	},
})
