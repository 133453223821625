
import { defineComponent, SetupContext } from 'vue'
import MyndshftInput from '@/components/form/input.vue'

export default defineComponent({
	name: 'edit-worklist-name',
	props: {
		item: Object,
		cancelPolling: Function,
		index: Number,
	},
	components: {
		MyndshftInput,
	},
	setup(_, context: SetupContext) {
		const onInput = (name: string) => {
			const isValid = !!(name.length && name.length > 1 && name.length < 256)
			context.emit('updateName', { name, isValid })
		}

		return {
			onInput,
		}
	},
})
