
import { defineComponent, ref, SetupContext, PropType } from 'vue'

import { IDisplayUser, IWorklist } from '@server/api/models/fe/worklist'

export default defineComponent({
	name: 'edit-worklist-user',
	props: {
		item: { type: Object as PropType<IWorklist>, required: true },
		users: Array as PropType<IDisplayUser[]>,
		index: Number,
		isLoading: { type: Boolean, default: false },
	},
	setup(props, context: SetupContext) {
		const user = ref({
			id: props.item.assigneeId,
			name: props.item.assigneeId.substr(0, props.item.assigneeId.indexOf('@')),
		})

		const onInput = (selectedUser: any) => {
			const id = selectedUser ? selectedUser.id : ''
			user.value = selectedUser || {
				id: '',
				name: '',
			}

			context.emit('updateUser', id)
		}

		return {
			user,
			onInput,
		}
	},
})
